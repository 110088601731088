//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import UploadIndex from '@/components/uploadPicture/index.vue'
export default {
  name: 'UploadFroms',
  // components: { UploadIndex },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      isMore: '',
      modelName: '',
      businessId: '',
      ISmodal: false,
      booleanVal: false
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};